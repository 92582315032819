<template>
<div class="su-wrapper">
    <nh-title title="区域编辑"></nh-title>
    <div class="su-container">
        <div class="survey_content_line">
            <YtInput :property="nameProperty" :value.sync="area.name"></YtInput>
        </div>
        <div class="survey_content_line">
            <YtMultiSelectDrop :property="hospitalProperty"
                               :value.sync="area.hospitalList" ></YtMultiSelectDrop>
        </div>
        <div class="survey_content_line">
            <YtInput :property="bigAreaNameProperty" :value.sync="area.bigAreaName"></YtInput>
        </div>
        <div class="survey_content_line">
            <YtInput :property="dmNameProperty" :value.sync="area.dmName"></YtInput>
        </div>
        <div class="survey_content_line">
            <YtInput :property="dmPhoneProperty" :value.sync="area.dmPhone"></YtInput>
        </div>
        <div class="survey_content_line">
            <YtInput :property="dmCityProperty" :value.sync="area.dmCity"></YtInput>
        </div>
        <div class="save-btn-wrapper">
            <nh-button class="a-bottom" @click="doSave">保存</nh-button>
        </div>
    </div>
</div>
</template>

<script>
import NhTitle from "@/components/patient/components/nh-title";
import NhButton from "@/components/patient/components/nh-button";
import YtMultiSelectDrop from "@/components/survey/components/properties/multiSelect/YtMultiSelectDrop";
import YtInput from "@/components/survey/components/properties/YtInput";
export default {
    name: "AreaEdit",
    components: {NhButton, NhTitle,YtMultiSelectDrop,YtInput},
    data() {
        return {
            areaId: undefined,
            area: {},
            cascaderProps: {
                multiple: true,
                label: 'name',
                value: 'name'
            },
            options: [],
            hospitalProperty:{
                name: '医院',
                selectDatasource: 'hospitalAll'
            },
            nameProperty: {
                name: '区域'
            },
            dmCityProperty: {
                name: '工作城市'
            },
            dmPhoneProperty: {
                name: '联系方式'
            },
            dmNameProperty: {
                name: '区域经理'
            },
            bigAreaNameProperty: {
                name: '大区'
            }
        }
    },
    computed: {},
    watch: {},
    created() {
        this.areaId = this.$route.query.id
        this.getData()
    },
    methods: {
        getData() {
            if(this.areaId) {
                this.$httpUtil.get(this.$urlConstant.nuohua.areaGetPutDel + this.areaId,res=>{
                    this.area = res.data
                },this)
            }
        },
        doSave() {
            if(!this.area.name) {
                this.$message.error('请输入区域')
                return
            }
            if(!this.area.hospitalList) {
                this.$message.error('请输入医院')
                return
            }
            if(!this.area.dmName) {
                this.$message.error('请输入姓名')
                return
            }
            if(!this.area.dmPhone) {
                this.$message.error('请输入电话')
                return
            }
            if(!this.area.dmCity) {
                this.$message.error('请输入城市')
                return
            }
            if(!this.area.bigAreaName) {
                this.$message.error('请输入大区')
                return
            }
            //处理hospital
            // this.area.hospitalList = this.area.hospitalList.map(hospitalArray=>hospitalArray.join())

            if(this.areaId) {
                this.$httpUtil.put(this.$urlConstant.nuohua.areaGetPutDel+this.areaId, this.area,()=>{
                    this.$message.success('保存成功')
                    setTimeout(() => {
                        this.$router.replace({name: 'AreaList'})
                    }, 1000)
                },this)
            }else {
                this.$httpUtil.post(this.$urlConstant.nuohua.areaCreate, this.area,()=>{
                    this.$message.success('保存成功')
                    setTimeout(() => {
                        this.$router.replace({name: 'AreaList'})
                    }, 1000)
                },this)
            }
        },
    },
}
</script>

<style scoped>

</style>